<template>
  <v-app class="access-background">
    <v-app-bar flat app class="access-background">
      <div class="d-flex align-center mx-auto">
        <v-img
          src="/images/logo-signi_com.png"
          transition="scale-transition"
          class="shrink"
          alt="Signi Logo"
          width="10rem"
          contain
          style="cursor: pointer"
          @click="goToLogin"
        />
      </div>
    </v-app-bar>
    <v-main>
      <v-container class="fill-height">
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'AccessLayout',
  methods: {
    goToLogin() {
      if ('login' === this.$route.name) {
        return;
      }

      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.access-background {
  background: url('~@/layouts/assets/bckg@2x.png') no-repeat fixed center;
  background-size: cover;
}
</style>
